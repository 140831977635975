<template>
  <main>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Назад"
      @back="() => $router.go(-1)"
    />
    <a-form-model style="padding: 20px" layout="vertical" :model="form">
      <a-form-model-item label="Заголовок*">
        <a-input
          v-decorator="[
            'text',
            {
              rules: [
                { required: true, message: 'Поле текст не может быть пустым!' },
              ],
            },
          ]"
          v-model="form.news_name"
          placeholder="Заголовок..."
        />
        <div v-if="test"></div>
      </a-form-model-item>
      <a-form-model-item label="Содержание*">
        <!-- <vue-editor
          :editor-toolbar="customToolbar"
          v-model="form.news_text"
          style="background-color: white"
        /> -->
        <editor
          ref="toastuiEditor"
          :initialValue="form.news_text"
          :options="defaultOptions"
          initialEditType="wysiwyg"
          @change="getMarkdown"
        />
      </a-form-model-item>
      <!-- <a-form-model-item label="Содержание*">
        <a-textarea
          :auto-size="{ minRows: 6, maxRows: 12 }"
          v-model="form.news_text"
          placeholder="Содержание..."
        />
      </a-form-model-item> -->
      <a-form-model-item
        label="Картинка*"
        style="background-color: white; padding: 10px"
      >
        <div style="display: flex; margin-bottom: 5px">
          <input
            type="file"
            id="img"
            name="img"
            @change="handleChange"
            accept="image/*"
          />
        </div>
        <div id="preview" style="max-height: 60%; max-width: 60%">
          <img
            v-if="item.imageUrl"
            :src="item.imageUrl"
            style="max-width: 100%"
          />
        </div>
      </a-form-model-item>

      <a-form-model-item>
        <a-button :disabled="!item.imageUrl" @click="addNews">
          Опубликовать
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </main>
</template>

<script>
import axios from "axios";
// import { VueEditor } from "vue2-editor";
import { Editor } from "@toast-ui/vue-editor";
export default {
  name: "News",
  components: { Editor },
  data: function () {
    return {
      defaultOptions: {
        minHeight: "300px",
        language: "en-US",
        useCommandShortcut: true,
        usageStatistics: true,
        hideModeSwitch: true,
        toolbarItems: [
          ["heading", "bold", "italic", "strike"],
          // ["hr", "quote"],
          ["ul", "ol"],
          ["link"],
        ],
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
      ],
      form: {
        news_name: "",
        news_text: "",
      },
      item: {
        image: null,
        imageUrl: null,
      },
      newsStatus: "draft",
      files: [],
      loading: false,
      supaimage: null,
      test: false,
    };
  },
  methods: {
    handleDelete() {
      console.log("delete goes here");
    },
    getMarkdown() {
      let markdown = this.$refs.toastuiEditor.invoke("getMarkdown");
      this.form.news_text = markdown;
    },
    handleChange(e) {
      const file = e.target.files[0];
      this.image = file;
      this.item.imageUrl = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onload = (e) => {
        this.supaimage = e.target.result;
      };
    },
    previewFiles() {
      this.files = this.$refs.myFiles.files;
    },
    addNews() {
      this.loading = true;
      let formData = new FormData();
      formData.append("name", this.form.news_name);
      formData.append("body", this.form.news_text);
      console.log(this.image);
      if (this.image) {
        formData.append("cover", this.image);
      }
      axios({
        method: "POST",
        url: "/cp/news/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          console.log(response);
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    item(val) {
      if (val.lenght > 0) this.test = true;
      else this.test = false;
    },
  },
};
</script>
